import React  from 'react';
import { RouteProps } from 'react-router-dom';

import './Button.sass';
type BtnProps = {className?: string, onClick?: Function, type?: 'button'|'submit'};

export const Button = ({children, className = '', type = 'button', onClick = (e?: MouseEvent) => {}}: RouteProps & BtnProps) => {
  if (!children)
    throw new Error('You need to provide children node for Button component');

  return (
    <button className={`app-button ${className}`} onClick={(e) => onClick(e)} type={type}>
      {children}
    </button>
  )
};
