import React from 'react';
import { RouteProps } from 'react-router-dom';

import './Container.sass';

export const Container = ({children}: RouteProps) => {
  return (
    <div className='app-container'>
      {children}
    </div>
  )
};
