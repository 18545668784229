import React from 'react';
import './App.css';
import { Landing } from './landing/Landing';
import { BlogIndex } from './blog/BlogIndex';
import { Swiper as SwiperBase, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/swiper.scss';
import { BlogShow } from './blog/BlogShow';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MODAL_ROOT } from './common/Modal';

SwiperBase.use([Navigation, Pagination, Scrollbar]);

function App() {
  return (
    <>
      <div className="App">
        <Router>
          <Switch>
            <Route path={'/blog/:id'} component={BlogShow}></Route>
            <Route path={'/blog'} component={BlogIndex}></Route>
            <Route path={'/'} component={Landing}></Route>
            {/*<Landing/>*/}
            {/*<BlogIndex/>*/}
            {/*<BlogShow/>*/}
          </Switch>
        </Router>
      </div>
      <div id={MODAL_ROOT}>
      </div>
    </>
  );
}

export default App;
