import { RouteComponentProps, RouteProps } from 'react-router-dom';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import './BlogIndex.sass';
import { Button } from '../common/Button';
import { Header } from '../common/Header';
import { Footer } from '../common/Footer';
import { Container } from "../common/Container";

import BlogSampleImage from './images/blog-sample.png';
import BlogRecentSampleImage from './images/blog-recent-sample.png';
import ArrowRight from './images/arrow-right.png';

export const BlogIndex = (props: RouteComponentProps) => {
  const trending = [
    'Here\'s The Simple Trick To Look Your Best In Selfies From Your Smartphone',
    'Here\'s The Simple Trick To Look Your Best In Selfies From Your Smartphone',
    'Here\'s The Simple Trick To Look Your Best In Selfies From Your Smartphone',
  ].map((text, i) => (
    <SwiperSlide key={i}>
      <a href='/blog/test' className='blog-trending-item'>
        <div className='blog-trending-item__logo-wrapper'>
          <img src={BlogSampleImage} className='blog-trending-item__logo' alt="trending logo"/>
        </div>
        <div className='blog-trending-item__text'>
          {text}
        </div>
      </a>
    </SwiperSlide>
  ));

  return (
    <div>
      <Header></Header>
{/* Main section */}
      <div className='blog-main'>
        <div className='blog-main__gradient'></div>
        <Container>
          <div className='blog-main__content'>
            <div className='blog-main__pre-title'>TOP ARTICLE</div>
            <div className='blog-main__title'>
              Reduce your reliance on booking platforms
            </div>
            <Button className='blog-main__continue-btn' onClick={() => props.history.push('/blog/test')}>Continue Reading</Button>
          </div>
        </Container>
      </div>
{/* Trending section */}
      <div className='blog-trending'>
        <Container>
          <div className='blog-trending-list'>
            <div className='blog-trending-pre-title blog-pre-title'>
              TRENDING NOW
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              navigation={{nextEl: '#nextTrendingArrow', prevEl: '#prevTrendingArrow'}}
              pagination={{el: '.swiper-pagination', type: 'bullets',}}
            >
              {trending}
            </Swiper>
            <div className='blog-trending-arrows'>
              <div id='prevTrendingArrow' className='blog-trending-arrow blog-trending-arrow--left'>
                <img src={ArrowRight} alt="arrow left"/>
              </div>
              <div id='nextTrendingArrow' className='blog-trending-arrow blog-trending-arrow--right'>
                <img src={ArrowRight} alt="arrow right"/>
              </div>
            </div>
          </div>
        </Container>
      </div>

{/* Blog Recent articles */}
      <div className='blog-recent'>
        <Container>
          <div className='blog-recent-articles'>
            <div className='blog-recent-pre-title blog-pre-title'>
              RECENT ARTICLES
            </div>
            <div className='blog-recent-list'>
              {
                [1,2,3,4].map((s, i) => (
                  <BlogRecent key={i}></BlogRecent>
                ))
              }
            </div>
          </div>
        </Container>
      </div>

      <Footer></Footer>
    </div>
  );
};

export const BlogRecent = () => {
  return (
    <a href='/blog/test' className='blog-recent-item'>
      <div className='blog-recent-item__logo-wrapper'>
        <img src={BlogRecentSampleImage} alt="" className='blog-recent-item__logo'/>
      </div>
      <div className='blog-recent-item__content-wrapper'>
        <div className='blog-recent-item__content'>
          <div className='blog-recent-item__title'>
            Scientists May Have Discovered A New State Of Liquid Water
          </div>
          <div className='blog-recent-item__text'>
            Scientists say they have found a strange transition state in liquid water. The findings were published in the International Journal of Nanotechnology.
          </div>
          <div className='blog-recent-item__divider'></div>
          <div className='blog-recent-item__date'>
            MARCH 27, 2017
          </div>
        </div>
      </div>
    </a>
  );
}
