import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import ReactDOM from 'react-dom';

import './Button.sass';
import './Modal.sass';

type ModalProps = {isOpen?: boolean, closeRequested: Function};
export const MODAL_ROOT = 'modal-root'
const MODAL_WRAPPER_CLASS = 'app-modal-wrapper';

export const Modal = ({children, isOpen = false, closeRequested}: RouteProps & ModalProps) => {
  const body = document.querySelector('body') as any;
  const [modalRoot, setModalRoot] = useState<HTMLElement|null>(null);
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    const modalRootElement = document.getElementById(MODAL_ROOT) as HTMLElement;
    setModalRoot(modalRootElement);

    if (isOpen) {
      body.classList.add('show-modal');
    } else {
      body.classList.remove('show-modal');
    }
  }, [isOpen]);

  const onBackdropClick = (e: any) => {
    if (e.target.closest(`.${MODAL_WRAPPER_CLASS}`)) {
      return;
    }

    closeRequested();
  };


  useEffect(() => {
    if (!activated) {
      setActivated(true);
    }

    if (activated && !isOpen) {
      closeRequested();
    }
  }, [isOpen]);

  if (!children)
    throw new Error('You need to provide children node for Modal component');

  if (!modalRoot || !isOpen) {
    return  <div></div>;
  }

  return ReactDOM.createPortal(
    <div className='app-modal-backdrop ' onClick={e => onBackdropClick(e as any)}>
      <div className={MODAL_WRAPPER_CLASS}>
        <div className={'app-modal-content' + (isOpen && 'is-open')}>
          {children}
        </div>
      </div>
    </div>,
    modalRoot
  );
};
